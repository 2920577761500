import './about.css';

import React from 'react';

import Team from '../../../../Assets/Images/team1.jpg';

function AboutUs() {
  return (
    <div className="col">
      <h1 style={{fontWeight: 'bold', fontSize: '2.5rem', textAlign: 'center'}}>
        We Design,
        <br /> Web Application & <br />
        Mobile Application
      </h1>

      <p style={{fontSize: '1.5rem', textAlign: 'center'}}>
        RR-INFOTECH is a leading web design and development
        <br /> company, dedicated to providing businesses of all <br />
        sizes with high-quality, custom-built websites that <br />
        help them succeed online.
      </p>
      <p style={{fontSize: '1.5rem', textAlign: 'center'}}>
        Our team of experienced designers, developers,
        <br /> and…
      </p>
      <a
        className="btn btn-success rounded-pill"
        href="/services"
        style={{display: 'block', margin: '0 auto', textAlign: 'center'}}>
        Read More
      </a>
    </div>
  );
}

export default function About() {
  return (
    <section>
      <div className="relative row about-Container">
        <AboutUs />
        <div className="col">
          <div className="image-container col" style={{textAlign: 'center'}}>
            <img
              alt="Team"
              src={Team}
              style={{maxWidth: '100%', height: 'auto'}}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
