import WorkTogether from 'Components/EstimateProject/WorkTogether';
import React from 'react';

import Footer from '../../Components/Footer/Footer';
import About from './Components/About/About';
import Header from './Components/Header/Header';
import OurServices from './Components/OurServices';
import OurWork from './Components/OurWork';

function Home() {
  return (
    <div>
      <Header />
      <About />
      <OurServices />
      <OurWork />
      <WorkTogether />
      <Footer />
    </div>
  );
}

export default Home;
