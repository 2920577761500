import AboutDevelopment from 'Components/AboutDevelopmet';
import Footer from 'Components/Footer/Footer';
import CenterImage from 'Components/Image';
import Title from 'Components/ImageTitle';
import NavBar from 'Components/Navbar';
import React from 'react';

function AppDevelopment() {
  const title = 'App Development, Web Development And Web Maintenance';

  const about =
    'At RR Infotech, Our team of experienced app developers is here to guide you through every step of the process, from brainstorming ideas to launching your app in the app store. We understand that creating an app can be a daunting task, but with our expertise, we can help make the process smoother and less stressful.';

  const about2 =
    'We specialize in developing apps for both iOS and Android platforms. Our team is well-versed in the latest technology and trends, ensuring that your app will be up-to-date and user-friendly. We also offer custom app development, ensuring that your app is tailored to your specific needs and requirements.';

  const about3 =
    'At RR-Infotech, we firmly believe that a website is not just a digital presence but a powerful embodiment of your brand,values, and unique identity. Thats why we go above and beyond to collaborate closely with our clients, understanding their vision, goals, and target audience.';

  const heading =
    'Creative Solutions for Your Mobile App Design, Web Design and Web Maintenance Needs';

  const aboutDevelopment =
    'But our service does not stop there. We offer ongoing support and maintenance to ensure that your app is running smoothly and efficiently. We also offer marketing services to help promote your app and increase visibility in the app store.';

  return (
    <>
      <NavBar />
      <Title animation={true} title={title} />
      <AboutDevelopment
        about={about}
        about2={about2}
        about3={about3}
        heading={heading}
      />
      <CenterImage aboutDevelopment={aboutDevelopment} />
      <Footer />
    </>
  );
}

export default AppDevelopment;
