import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import {Carousel, Col, Container, Row} from 'react-bootstrap';
import {useMediaQuery} from 'react-responsive';

import Image1 from '../../../../Assets/Images/images2.jpeg';
import Image2 from '../../../../Assets/Images/images3.jpeg';
import Image3 from '../../../../Assets/Images/images4.jpeg';
import Image4 from '../../../../Assets/Images/images5.jpeg';
import Image6 from '../../../../Assets/Images/images7.jpeg';
import Image7 from '../../../../Assets/Images/images8.png';

const arr = [
  {image: Image1, id: 1},
  {image: Image2, id: 2},
  {image: Image3, id: 3},
  {image: Image4, id: 4},
  {image: Image6, id: 5},
  {image: Image7, id: 7},
];

function OurWork() {
  const isSmallScreen = useMediaQuery({maxWidth: 768});

  return (
    <div>
      <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
        <div style={{textAlign: 'center', marginTop: '30px'}}>
          <h1
            style={{
              fontFamily: 'sans-serif',
              fontSize: '50px',
            }}>
            Some Of Our Finest Work
          </h1>
        </div>

        <Container className="mt-5 mb-5">
          <Row>
            <Col xs={12}>
              {isSmallScreen ? (
                <Carousel>
                  {arr.map(item => (
                    <Carousel.Item key={item.id}>
                      <img
                        alt={`Work ${item.id}`}
                        className="d-block w-100"
                        src={item.image}
                        style={{borderRadius: '20px'}}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    overflowX: 'auto',
                    margin: '0 -10px',
                  }}>
                  {arr.map(item => (
                    <img
                      key={item.id}
                      alt={`Work ${item.id}`}
                      className="d-block mr-3"
                      src={item.image}
                      style={{
                        borderRadius: '20px',
                        maxWidth: '200px',
                        maxHeight: '200px',
                        marginRight: '10px',
                      }}
                    />
                  ))}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </ScrollAnimation>
    </div>
  );
}

export default OurWork;
