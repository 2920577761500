import './imageTitle.css';

import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import BackgroundImage from '../../Assets/Images/Bg_img.jpg';

export default function Title(props) {
  const {title, animation} = props;

  return (
    <div
      style={{
        padding: 60,
        position: 'relative',
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        marginTop: 70,
      }}>
      {animation === true ? (
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
          <h1 className="titleContainer">{title}</h1>
        </ScrollAnimation>
      ) : (
        <h1 className="titleContainer">{title}</h1>
      )}
    </div>
  );
}
