import './development.css';

import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

export default function AboutDevelopment(props) {
  const {heading, about, about2, about3} = props;
  return (
    <div className="developmentContainer">
      <ScrollAnimation animateIn="animate__slideInLeft" animateOnce={true}>
        <div className="developmentSubContainer ">
          <h2 className="developmentHeading">{heading}</h2>
          <div className="buttonContainer">
            <a className="btn btn-success rounded-pill" href="/contact-us">
              Get Started
            </a>
          </div>
        </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="animate__slideInRight" animateOnce={true}>
        <div className="developmentSubContainer ">
          <h4 style={{fontWeight: 'lighter', fontFamily: 'sans-serif'}}>
            {about}
          </h4>
          <h4 style={{fontWeight: 'lighter', fontFamily: 'sans-serif'}}>
            {about2}
          </h4>
          <h4 style={{fontWeight: 'lighter', fontFamily: 'sans-serif'}}>
            {about3}
          </h4>
        </div>
      </ScrollAnimation>
    </div>
  );
}
