import Footer from 'Components/Footer/Footer';
import Title from 'Components/ImageTitle';
import NavBar from 'Components/Navbar';
import React from 'react';

import Image1 from '../../Assets/Images/images2.jpeg';
import Image2 from '../../Assets/Images/images3.jpeg';
import Image3 from '../../Assets/Images/images4.jpeg';
import Image4 from '../../Assets/Images/images5.jpeg';
import Image5 from '../../Assets/Images/images6.jpeg';
import Image6 from '../../Assets/Images/images7.jpeg';
import Image7 from '../../Assets/Images/images8.png';
import Image8 from '../../Assets/Images/images9.jpeg';

const arr = [
  {image: Image1, id: 1},
  {image: Image2, id: 2},
  {image: Image3, id: 3},
  {image: Image4, id: 4},
  {image: Image6, id: 5},
  {image: Image5, id: 6},
  {image: Image7, id: 7},
  {image: Image8, id: 8},
];

function Portfolio() {
  const title = 'Our Best Work';

  return (
    <div>
      <NavBar />
      <Title animation={true} title={title} />

      <div className="container mt-5 mb-5">
        <div className="row text-center">
          {arr.map(item => {
            return (
              <div
                key={item.id}
                className="rounded col-lg-3 col-md-4 col-sm-6 mt-4 mx-auto"
                style={{marginInline: '15px'}}>
                <img
                  alt=""
                  src={item.image}
                  style={{borderRadius: '20px', width: '100%'}}
                />
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Portfolio;
