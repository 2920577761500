import './footer.css';

import Logo from 'Components/Logo';
import React from 'react';

import Location from '../../Assets/Svg/location.svg';
import Mail from '../../Assets/Svg/mail.svg';

function CompanyName() {
  return (
    <div
      style={{
        textAlign: 'center',
        position: 'absolute',
        right: 0,
        left: 0,
        bottom: -70,
      }}>
      <h1
        style={{
          fontSize: 150,
          opacity: 0.1,
          fontWeight: 'bolder',
          color: 'grey',
        }}>
        RR-INFOTECH
      </h1>
    </div>
  );
}

// function UseFulLinks() {
//   return (
//     <div>
//       <h5 style={{fontFamily: 'sans-serif', fontWeight: 'bold'}}>UseFul Links</h5>
//       <h6 style={{fontFamily: 'sans-serif', fontWeight: 'bold'}}> FAQ</h6>
//       <h6 style={{fontFamily: 'sans-serif', fontWeight: 'bold'}}>Privacy Policy</h6>
//       <h6 style={{fontFamily: 'sans-serif', fontWeight: 'bold'}}>Terms and Conditions</h6>
//       <h6 style={{fontFamily: 'sans-serif', fontWeight: 'bold'}}>Support</h6>
//     </div>
//   );
// }

function ContactUs() {
  return (
    <div className="footerContactUsContainer">
      <h5 className="contactUsHeading"> Contact Us</h5>
      <div style={{display: 'flex'}}>
        <img
          src={Location}
          style={{width: '20px', height: '20px', color: '#41435E'}}
        />
        <h6 style={{marginLeft: '5px', color: '#41435E'}}>Gujarat,India</h6>
      </div>

      <a
        href="mailto:contact@rrinfotech.co.in"
        style={{display: 'flex', textDecoration: 'none'}}>
        <img height="20px" src={Mail} width="20px" />
        <h6 style={{marginLeft: '5px', color: '#41435E'}}>
          contact@rrinfotech.co.in
        </h6>
      </a>
    </div>
  );
}

function Footer() {
  return (
    <div className="footerContainer">
      <div className="footerSubContainer">
        <div className="footerAboutCompanyContainer">
          <Logo />
          <h6 className="footerAboutCompany">
            We offer the services you need to succeed
            <br /> online. Get in touch today and let us help you
            <br /> transform your online presence.
          </h6>
        </div>
        <ContactUs />
      </div>
      <CompanyName />
    </div>
  );
}

export default Footer;
