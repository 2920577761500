import './ourServices.css';

import React from 'react';

import Computer from '../../../../Assets/Svg/computer.svg';
import Desktop from '../../../../Assets/Svg/desktop.svg';
import Mobile from '../../../../Assets/Svg/mobile.svg';
import Task from '../../../../Assets/Svg/task.svg';

function WhatWeAre() {
  return (
    <div className="col what-we-are-container">
      <h1 style={{fontFamily: 'serif', fontWeight: 'bold', fontSize: '50px'}}>
        Empowering <br />
        Your Online
        <br />
        Presence with <br /> Expert Services
      </h1>
      <p style={{fontFamily: 'serif', fontSize: '25px'}}>
        We’re here to help you bring your
        <br /> business to life online with everything
        <br /> from beautiful site design and
        <br /> development to effective digital <br />
        marketing solutions. Our skilled staff <br /> works arduously to develop
        <br />
        specialized solutions catered to your <br />
        particular requirements, making sure <br />
        that your brand stands out
        <br /> and achieves its full potential in the digital <br />
        world.
      </p>
      <a className="btn btn-success rounded-pill" href="/services">
        Read More
      </a>
    </div>
  );
}

function Cards() {
  return (
    <div className="col">
      <div className="ourServicesCards">
        <div className="servicesCardSubContainer">
          <div className="brandingCard">
            <img alt="Task" className="brandingImage" src={Task} />
            <h4
              style={{fontWeight: 'bold', marginTop: '50px', color: '#41435E'}}>
              Branding
            </h4>
          </div>
          <div className="appDevelopmentContainer">
            <img
              alt="Mobile"
              src={Mobile}
              style={{height: '40%', width: '40%', display: 'block'}}
            />
            <h4 style={{fontWeight: 'bold', marginTop: '50px', color: 'white'}}>
              App Development
            </h4>
          </div>
        </div>

        <div className="servicesCardContainer1">
          <div className="webDesignContainer">
            <img
              alt="Computer"
              src={Computer}
              style={{height: '30%', width: '30%', display: 'block'}}
            />
            <h4
              style={{fontWeight: 'bold', marginTop: '50px', color: '#41435E'}}>
              Web Design
            </h4>
          </div>
          <div className="webDesignContainer">
            <img
              alt="Desktop"
              src={Desktop}
              style={{height: '30%', width: '30%', display: 'block'}}
            />
            <h4
              style={{fontWeight: 'bold', marginTop: '50px', color: '#41435E'}}>
              UI/UX Design
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

function OurServices() {
  return (
    <div className="container-fluid">
      <div className="ourServicesContainer row">
        <Cards />
        <WhatWeAre />
      </div>
    </div>
  );
}

export default OurServices;
