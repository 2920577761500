import './aboutUs.css';

import WorkTogether from 'Components/EstimateProject/WorkTogether';
import Footer from 'Components/Footer/Footer';
import Title from 'Components/ImageTitle';
import NavBar from 'Components/Navbar';
import React from 'react';

import Office from '../../Assets/Images/office.jpg';
import Check from '../../Assets/Svg/check.svg';
import Task from '../../Assets/Svg/pen.svg';
import Team from '../../Assets/Svg/team.svg';

function OurMission() {
  return (
    <div className="missionCard">
      <h5 style={{fontFamily: 'Playfair Display', fontSize: '40px'}}>
        Mission
      </h5>
      <h6
        style={{
          fontFamily: 'sans-serif',
          fontWeight: 'lighter',
          fontSize: '20px',
        }}>
        RR-Infotech is dedicated to empowering businesses in the digital age by
        delivering innovative and reliable technology solutions. Our mission is
        to be at the forefront of the digital revolution, providing tailored
        services that address unique challenges and enable clients to optimize
        efficiency, enhance productivity, and drive growth. Through strategic
        partnerships and expert guidance, we strive to empower businesses to
        embrace the digital future and achieve their full potential in an
        increasingly interconnected world.
      </h6>
    </div>
  );
}

function OurVision() {
  return (
    <div className="missionCard">
      <h5 style={{fontFamily: 'Playfair Display', fontSize: '40px'}}>Vision</h5>
      <h6
        style={{
          fontFamily: 'sans-serif',
          fontWeight: 'lighter',
          fontSize: '20px',
        }}>
        RR-Infotech envisions itself as a trusted global leader in providing
        transformative technology solutions, driving businesses towards a
        sustainable and digitally empowered future. Our vision encompasses being
        recognized as the go-to partner for businesses seeking innovative and
        reliable technology services. We aim to exceed client expectations by
        staying ahead of technological advancements, embracing sustainability
        practices, fostering collaboration and innovation, and attracting top
        talent.
      </h6>
    </div>
  );
}

function Image() {
  return (
    <div className="col">
      <img className="mangoImage" src={Office} />
    </div>
  );
}

function About() {
  return (
    <div className="col" style={{padding: 30}}>
      <h5 style={{fontFamily: 'Playfair Display', fontSize: '48px'}}>
        {' '}
        WHAT WE ARE
      </h5>
      <h6
        style={{
          fontFamily: 'sans-serif',
          fontWeight: 'lighter',
          fontSize: '20px',
        }}>
        At RR-INFOTECH, we understand the unique challenges that <br />
        small businesses face when it comes to establishing an <br />
        online presence. That’s why we offer a range of
        <br /> affordable web design and digital marketing solutions
        <br /> that are tailored to meet the specific needs of small-
        <br />
        scale businesses. We work closely with our clients to <br />
        develop customized strategies that help them increase <br />
        their visibility and attract more customers. <br />
      </h6>
      <h6
        style={{
          fontFamily: 'sans-serif',
          fontWeight: 'lighter',
          fontSize: '20px',
          marginTop: '10px',
        }}>
        For larger-scale businesses, we offer a range of <br />
        comprehensive solutions that can help them stay ahead
        <br /> of the competition. Our team of experts has experience <br />
        working with businesses of all sizes and can help <br />
        develop a strategy that aligns with your business goals <br />
        and objectives.
      </h6>
    </div>
  );
}

function Cards() {
  return (
    <div className="row cardMainContainer">
      <div className="col">
        <div className="cardSubContainer col">
          <div style={{display: 'flex', textAlign: 'center'}}>
            <img
              src={Team}
              style={{height: '10%', width: '10%', display: 'block'}}
            />
            <h6
              style={{
                marginTop: 5,
                marginLeft: '10px',
                fontFamily: 'sans-serif',
              }}>
              LEARN
            </h6>
          </div>
          <h4
            style={{
              fontWeight: 'bold',
              marginTop: '10px',
              color: '#41435E',
            }}>
            We Ask the Right <br />
            Questions About Your
            <br /> Business
          </h4>
          <h6
            style={{
              fontFamily: 'sans-serif',
              fontWeight: 'lighter',
              fontSize: '20px',
            }}>
            Before we start any project, we take the time to get to know your
            business. We ask the right questions to gain a deep understanding of
            your business, target audience, and competition.
          </h6>
        </div>
      </div>
      <div className="col">
        <div className="cardSubContainer col">
          <div style={{display: 'flex', textAlign: 'center'}}>
            <img
              src={Task}
              style={{height: '10%', width: '10%', display: 'block'}}
            />
            <h6
              style={{
                marginTop: 5,
                marginLeft: '10px',
                fontFamily: 'sans-serif',
              }}>
              PLAN
            </h6>
          </div>
          <h4
            style={{
              fontWeight: 'bold',
              marginTop: '10px',
              color: '#41435E',
            }}>
            Developing Customized
            <br /> Solutions
          </h4>
          <h6
            style={{
              fontFamily: 'sans-serif',
              fontWeight: 'lighter',
              fontSize: '20px',
            }}>
            Once we have a clear
            <br /> understanding of your business, <br />
            we work alongside you to develop <b /> customized solutions that
            meet <br /> your specific needs.
            <br /> We deliver a solution that
            <br /> meets your expectations.
          </h6>
        </div>
      </div>
      <div className="col">
        <div className="cardSubContainer col">
          <div style={{display: 'flex', textAlign: 'center'}}>
            <img
              src={Check}
              style={{height: '10%', width: '8%', display: 'block'}}
            />
            <h6
              style={{
                marginTop: 5,
                marginLeft: '10px',
                fontFamily: 'sans-serif',
              }}>
              EXECUTE
            </h6>
          </div>
          <h4
            style={{
              fontWeight: 'bold',
              marginTop: '10px',
              color: '#41435E',
            }}>
            Launching Your <br />
            Solutions
          </h4>
          <h6
            style={{
              fontFamily: 'sans-serif',
              fontWeight: 'lighter',
              fontSize: '20px',
            }}>
            We don’t just develop your solutions
            <br /> and leave you to it. We work
            <br /> alongside you to launch your <br />
            solutions and provide ongoing <br />
            support and maintenance to ensure
            <br /> that they continue to meet your
            <br /> needs over time.
          </h6>
        </div>
      </div>
    </div>
  );
}

function AboutUs() {
  const title = 'We Do Things That Matter';
  return (
    <>
      <NavBar />
      <Title animation={true} title={title} />

      {/* Removed ScrollAnimation for Cards */}
      <div className="cardMainContainer">
        <Cards />
      </div>

      <div className="imageSubContainer row">
        <Image />
        <About />
      </div>

      {/* Removed ScrollAnimation for OurMission and OurVision */}
      <div className="row cardContainer">
        <OurMission />
        <OurVision />
      </div>

      <WorkTogether />
      <Footer />
    </>
  );
}

export default AboutUs;
