import React from 'react';

import logo from '../Assets/Images/logo_final.png';

function Logo() {
  return (
    <div style={{display: 'flex'}}>
      <img
        alt="Company Logo"
        className="d-inline-block align-top"
        height="50"
        src={logo}
        width="50"
      />
      <div style={{marginLeft: '10px'}}>
        <h5
          style={{color: '#41435E', fontFamily: 'initial', fontWeight: 'bold'}}>
          RR-INFOTECH
          <h6 style={{color: '#41435E', fontFamily: 'initial'}}>
            {' '}
            Grow Up Together
          </h6>
        </h5>
      </div>
    </div>
  );
}

export default Logo;
