import 'bootstrap/dist/css/bootstrap.min.css';
import './contactUs.css';

import Footer from 'Components/Footer/Footer';
import Title from 'Components/ImageTitle';
import NavBar from 'Components/Navbar';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import React from 'react';
import * as Yup from 'yup';

import Location from '../../Assets/Svg/location.svg';
import Mail from '../../Assets/Svg/mail.svg';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  number: Yup.number().required('Mobile No is required'),
  query: Yup.string().required('Message is required'),
});

function ContactUs() {
  const title = 'Lets Build Your Dream Website Together!';

  const initialValues = {
    name: '',
    email: '',
    number: '',
    query: '',
    service: '',
  };

  const onSubmit = async (values, {resetForm}) => {
    try {
      console.log(values);

      resetForm();
    } catch (error) {
      console.error('Form submission error:', error);
    }
  };

  return (
    <div>
      <NavBar />
      <Title animation={false} title={title} />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mb-4">
            <div className="text-center">
              <h1>Lets Talk</h1>
              <div className="formContainer">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}>
                  <Form>
                    <div className="row mb-3">
                      <div className="col">
                        <Field
                          className="form-control"
                          name="name"
                          placeholder="Name"
                          type="text"
                        />
                        <ErrorMessage component="div" name="name" />
                      </div>
                      <div className="col">
                        <Field
                          aria-label="Default select example"
                          as="select"
                          className="form-select"
                          name="service">
                          <option label="Select" value="" />
                          <option label="Website Development" value="1" />
                          <option label="App Development" value="2" />
                          <option label="Website Maintenance" value="3" />
                        </Field>
                        <ErrorMessage component="div" name="service" />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col">
                        <Field
                          className="form-control"
                          name="email"
                          placeholder="Email"
                          type="email"
                        />
                        <ErrorMessage component="div" name="email" />
                      </div>
                      <div className="col">
                        <Field
                          className="form-control"
                          name="number"
                          placeholder="Mobile No"
                          type="number"
                        />
                        <ErrorMessage component="div" name="number" />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col">
                        <Field
                          as="textarea"
                          className="form-control"
                          name="query"
                          placeholder="Message"
                          rows="5"
                        />
                        <ErrorMessage component="div" name="query" />
                      </div>
                    </div>

                    <div className="ps-4">
                      <button
                        className="btn btn-outline-secondary me-2"
                        type="reset">
                        Reset
                      </button>
                      <button
                        className="btn btn-outline-secondary"
                        type="submit">
                        Submit
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
          <div className="col-lg-6 contactUsContainer">
            <h1>Get in Touch with Us</h1>
            <h5 style={{fontFamily: 'sans-serif', fontWeight: 'lighter'}}>
              If you’re looking for professional website design services, look
              no <br />
              further than RR-Infotech. Contact us today to learn more about our
              <br /> services and how we can help your business achieve its
              <br /> online goals.
            </h5>
            <div style={{marginTop: 20}}>
              <div style={{display: 'flex'}}>
                <img height="20px" src={Location} width="20px" />
                <h6 style={{marginLeft: '5px'}}>Gujarat, India</h6>
              </div>

              <a
                href="mailto:contact@rrinfotech.in"
                style={{display: 'flex', textDecoration: 'none'}}>
                <img height="20px" src={Mail} width="20px" />
                <h6 style={{color: 'black', marginLeft: '5px'}}>
                  contact@rrinfotech.co.in
                </h6>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
