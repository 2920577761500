import './workTogether.css';

import React from 'react';
import {useNavigate} from 'react-router-dom';

export default function WorkTogether() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/contact-us');
  };
  return (
    <div className="estimateProjectContainer">
      <h3 style={{color: '#ffff'}}>
        {' '}
        Need a Successful Project? Lets Start Working Together!
      </h3>
      <button
        className="btn btn-outline-light"
        type="button"
        onClick={handleNavigate}>
        Estimate Project
      </button>
    </div>
  );
}
