import './navbar.css';

import React from 'react';
import {useState} from 'react';
import {Nav, Navbar} from 'react-bootstrap';
import {Link, useLocation} from 'react-router-dom';

import Logo from '../Logo';

const HEADER_ITEMS = [
  {path: '/', label: 'Home'},
  {path: '/about-us', label: 'About Us'},
  {path: '/services', label: 'Services'},
  {path: '/portfolio', label: 'Portfolio'},
  {path: '/contact-us', label: 'ContactUs'},
];

export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Navbar
      collapseOnSelect
      bg="light"
      className="fixed-top"
      expand="lg"
      expanded={isOpen}
      style={{padding: '10px 20px'}}>
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleNavbar} />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
          <Logo />
          <div className="navbarContainer">
            {HEADER_ITEMS.map(item => {
              const {path, label} = item;

              const isActive = location.pathname === path;

              return (
                <Nav.Link
                  key={label}
                  as={Link}
                  style={{color: isActive ? '#41435E' : ''}}
                  to={path}>
                  <div
                    className="border-style"
                    style={{borderColor: isActive ? '#41435E' : ''}}>
                    <h5>{label}</h5>
                  </div>
                </Nav.Link>
              );
            })}
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
