import './image.css';

import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import Image from '../../Assets/Images/imac.jpeg';

export default function CenterImage(props) {
  const {aboutDevelopment} = props;
  return (
    <div className="imageContainer">
      <ScrollAnimation animateIn="animate__slideInLeft" animateOnce={true}>
        <div style={{marginTop: 20}}>
          <h5
            style={{
              fontFamily: 'sans-serif',
              fontWeight: 'lighter',
              fontSize: '30px',
            }}>
            {aboutDevelopment}
          </h5>
        </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="animate__fadeInDown" animateOnce={true}>
        <div>
          <img className="image" height="80%" src={Image} width="70%" />
        </div>
      </ScrollAnimation>
    </div>
  );
}
