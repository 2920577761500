import './header.css';

import NavBar from 'Components/Navbar';
import React, {useState} from 'react';

import BackgroundImage from '../../../../Assets/Images/mac.jpg';
import Task from '../../../../Assets/Svg/lightbulb-regular.svg';
import Smart from '../../../../Assets/Svg/smart.svg';
import Team from '../../../../Assets/Svg/team.svg';

function Card() {
  return (
    <div className="card1">
      <div className="headerCardContainer">
        <img className="headerImage" src={Task} />
        <h4 className="headerCreative">Creative Design</h4>
        <p>
          we specialize in creating visually stunning designs that not only
          capture the attention of your customers, but also convey your brand
          message effectively.
        </p>
      </div>

      <div className="cardSubContainer1">
        <img className="headerImage" src={Team} />
        <h4 className="headerCreative">Super Ideas</h4>
        <p>
          Whether you need a new marketing strategy, a unique website feature,
          or any other out-of-the-box solution, we are here to help.
        </p>
      </div>
      <div className="headerMainContainer">
        <img className="headerImage" src={Smart} />
        <h4 className="headerCreative"> Smart Planing</h4>
        <p>
          Whether you are looking to launch a new product, revamp your website,
          or create a marketing campaign, we are here to help.
        </p>
      </div>
    </div>
  );
}

function Heading() {
  return (
    <div
      className="background-container"
      style={{
        position: 'relative',
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '700px',
        padding: '20px',
        textAlign: 'center',
      }}>
      <p className="headerTitle">TURNING YOUR ONLINE</p>
      <p className="vision">VISION INTO REALITY</p>
      <p className="headerHeading">
        If You’re Looking for a Web Design Company That Can Help Take Your
        Online Presence to the Next Level, Look No Further Than Our Team of
        Experts.
      </p>
      <Card />
    </div>
  );
}

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div style={{marginTop: 70}}>
        <NavBar isOpen={isOpen} toggleNavbar={toggleNavbar} />
        <Heading />
      </div>
    </>
  );
}

export default Header;
