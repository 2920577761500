import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {Route, Routes} from 'react-router-dom';
import AboutUs from 'Screens/AboutUs/AboutUs';
import ContactUs from 'Screens/ContactUs';
import Portfolio from 'Screens/Portfolio';
import Services from 'Screens/Services';

import Home from './Screens/Home';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route index element={<Home />} path="/" />
        <Route element={<AboutUs />} path="about-us" />
        <Route element={<Services />} path="services" />
        <Route element={<Portfolio />} path="portfolio" />
        <Route element={<ContactUs />} path="contact-us" />
      </Routes>
    </Router>
  );
};

export default App;
